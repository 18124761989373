import { Navbar, Container, Table, Nav, Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  useHistory
} from "react-router-dom";
import { properties } from './properties'

function App() {
  return (
    <Router>
    <Navbar bg="dark" expand="lg" variant="dark">
      <Container>
        <Navbar.Brand href="#home">Olsen Olsen Construction</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">Today</Nav.Link>
            <Nav.Link href="/daily">Daily</Nav.Link>
            <Nav.Link href="/monthly">Monthly</Nav.Link>
            <Nav.Link href="/individual">Individual</Nav.Link>
            <Nav.Link href="/byconstruction">By Construction</Nav.Link>
            <Nav.Link href="/users">Users</Nav.Link>
            <Nav.Link href="/constructions">Constructions</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <Container>
      <Switch>
        <Route path="/addclock">
          <AddClock />
        </Route>
        <Route path="/individual">
          <Individual />
        </Route>
        <Route path="/daily">
          <Daily />
        </Route>
        <Route path="/monthly">
          <Monthly />
        </Route>
        <Route path="/byconstruction">
          <ByConstruction />
        </Route>
        <Route path="/byconstructionfull">
          <ByConstructionAlways />
        </Route>
        <Route path="/users">
          <Users />
        </Route>
        <Route path="/createuser">
          <CreateUser />
        </Route>
        <Route path="/constructions">
          <Constructions />
        </Route>
        <Route path="/addconstruction">
          <CreateConstruction />
        </Route>
        <Route path="/edit/:id">
          <EditClock />
        </Route>
        <Route path="/">
          <Today />
        </Route>
      </Switch>
    </Container>
    </Router>
  );
}

function Users(){
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    };
    Promise.all([
        fetch(properties.api + '/public/users', requestOptions).then((response) => response.json().then((json) => {
          setData(json);
        }).catch((error) => console.error(error))),
    ]).then(() => {
        
    })
  }, [])

  return(
    <>
    <br />
    <div class="row">
      <div class="col-md-6">
        <h2>Users</h2>
      </div>
      <div class="col-md-6" style={{textAlign: 'end'}}>
        <a class="btn btn-success" href="/createuser">Create user</a>
      </div>
    </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>NIF</th>
            <th>Name</th>
            <th>Surname</th>
            <th>Phone</th>
            <th>Hourly salary</th>
          </tr>
        </thead>
        <tbody>
          {data.map((r) => (
            <tr>
              <td>{r.nif}</td>
              <td>{r.name}</td>
              <td>{r.surname}</td>
              <td>{r.phone}</td>
              <td>{r.base_salary.toFixed(2)} €</td>
            </tr>
          ))}
        </tbody>
      </Table>
      </>
  )
}

function CreateUser(){
  const [nif, setNif] = React.useState("");
  const [name, setName] = React.useState("");
  const [surname, setSurname] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [salary, setSalary] = React.useState("");
  const [password, setPassword] = React.useState("");

  const create = (evt) => {
    evt.preventDefault();
    const postData = { 'nif': nif, 'name': name, 'surname': surname, 'password': password, 'phone': phone, 'base_salary': salary };
    console.log(postData);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(postData)
    };
    Promise.all([
        fetch(properties.api + '/api/register', requestOptions).then((response) => response.json().then((json) => {
          window.location.href = "/users";
        }).catch((error) => console.error(error))),
    ]).then(() => {
        
    })
  }

  return(
    <form onSubmit={create}>
      <Form.Group className="mb-3">
        <Form.Label>NIF:</Form.Label>
        <Form.Control type="text" placeholder="NIF" onChange={(v) => setNif(v.target.value)} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Name:</Form.Label>
        <Form.Control type="text" placeholder="Name" onChange={(v) => setName(v.target.value)} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Surname:</Form.Label>
        <Form.Control type="text" placeholder="Surname" onChange={(v) => setSurname(v.target.value)} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Phone:</Form.Label>
        <Form.Control type="text" placeholder="Phone" onChange={(v) => setPhone(v.target.value)} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Salary:</Form.Label>
        <Form.Control type="text" placeholder="0.0" onChange={(v) => setSalary(v.target.value)} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Password:</Form.Label>
        <Form.Control type="text" placeholder="Password" onChange={(v) => setPassword(v.target.value)} />
      </Form.Group>
      <Button variant="primary" type="submit">
        Submit
      </Button>
    </form>
  )
}

function Constructions(){
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    };
    Promise.all([
        fetch(properties.api + '/public/constructions', requestOptions).then((response) => response.json().then((json) => {
          setData(json);
        }).catch((error) => console.error(error))),
    ]).then(() => {
        
    })
  }, [])

  return(
    <>
    <br />
    <div class="row">
      <div class="col-md-6">
        <h2>Constructions</h2>
      </div>
      <div class="col-md-6" style={{textAlign: 'end'}}>
        <a class="btn btn-success" href="/addconstruction">Add construction</a>
      </div>
    </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Clock radius</th>
          </tr>
        </thead>
        <tbody>
          {data.map((r) => (
            <tr>
              <td>{r.name}</td>
              <td>{r.radius} m</td>
            </tr>
          ))}
        </tbody>
      </Table>
      </>
  )
}

function CreateConstruction(){
  const [name, setName] = React.useState("");
  const [lat, setLat] = React.useState("");
  const [lng, setLng] = React.useState("");
  const [radius, setRadius] = React.useState("");

  const create = (evt) => {
    evt.preventDefault();
    const postData = {'name': name, 'point_lat': lat, 'point_lng': lng, 'radius': radius};
    console.log(postData);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(postData)
    };
    Promise.all([
        fetch(properties.api + '/public/construction/create', requestOptions).then((response) => response.json().then((json) => {
          window.location.href = "/constructions";
        }).catch((error) => console.error(error))),
    ]).then(() => {
        
    })
  }

  return(
    <form onSubmit={create}>
      <Form.Group className="mb-3">
        <Form.Label>Name:</Form.Label>
        <Form.Control type="text" placeholder="Name" onChange={(v) => setName(v.target.value)} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Latitude:</Form.Label>
        <Form.Control type="text" placeholder="Latitude" onChange={(v) => setLat(v.target.value)} />
        <Form.Text className="text-muted">
          Get coordinates from <a target="_blank" rel="noreferrer" href="https://maps.google.com">Google Maps</a>
        </Form.Text>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Longitude:</Form.Label>
        <Form.Control type="text" placeholder="Longitude" onChange={(v) => setLng(v.target.value)} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Clock radius (meters):</Form.Label>
        <Form.Control type="text" placeholder="0" onChange={(v) => setRadius(v.target.value)} />
      </Form.Group>
      <Button variant="primary" type="submit">
        Submit
      </Button>
    </form>
  )
}

function Today(){
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    };
    Promise.all([
        fetch(properties.api + '/public/clocksToday', requestOptions).then((response) => response.json().then((json) => {
          setData(json);
        }).catch((error) => console.error(error))),
    ]).then(() => {
        
    })
  }, [])

  return(
    <>
    <h2>Clocks today</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Worker</th>
            <th>Clock-In Time</th>
            <th>Clock-Out Time</th>
            <th>Hours</th>
            <th>Construction In</th>
            <th>Construction Out</th>
            <th>Notes</th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map((r) => (
            <tr>
              <td>{r.worker.name} {r.worker.surname}</td>
              <td>{r.time_in}</td>
              <td>{r.timeOut}</td>
              <td>{r.hours.toFixed(2)}</td>
              <td>{r.construction_in.name}</td>
              <td>{r.construction_out == null ? "" : r.construction_out.name}</td>
              <td>{r.note}</td>
              <td><a href={"https://www.google.com/maps/search/?api=1&query=" + r.lat_in + "," + r.lng_in}>View Map In</a></td>
              <td><a href={"https://www.google.com/maps/search/?api=1&query=" + r.lat_out + "," + r.lng_out}>View Map Out</a></td>
              <td><a href={"edit/" + r.id}>Edit</a></td>
            </tr>
          ))}
        </tbody>
      </Table>
      <a className="btn btn-success" href="/addclock">Add record/holidays</a>
      </>
  )
}

function Daily(){
  const [data, setData] = React.useState([]);

  const [day, setDay] = React.useState("");
  const [month, setMonth] = React.useState("");
  const [year, setYear] = React.useState("");

  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    
  }, [])

  const checkDay = (evt) => {
    evt.preventDefault();
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    };
    Promise.all([
        fetch(properties.api + '/public/report/clocks/' + day + '/' + month + '/' + year, requestOptions).then((response) => response.json().then((json) => {
          setData(json);
          setShow(true);
        }).catch((error) => console.error(error))),
    ]).then(() => {
        
    })
  }

  return(
    <>
    {show ?
    <>
    <h2>Clocks on {day}/{month}/{year}</h2>
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Worker</th>
          <th>Clock-In Time</th>
          <th>Clock-Out Time</th>
          <th>Hours</th>
          <th>Construction In</th>
          <th>Construction Out</th>
          <th>Notes</th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {data.map((r) => (
          <tr>
            <td>{r.worker.name} {r.worker.surname}</td>
            <td>{r.time_in}</td>
            <td>{r.timeOut}</td>
            <td>{r.hours.toFixed(2)}</td>
            <td>{r.construction_in.name}</td>
            <td>{r.construction_out == null ? "" : r.construction_out.name}</td>
            <td>{r.note}</td>
            <td><a href={"https://www.google.com/maps/search/?api=1&query=" + r.lat_in + "," + r.lng_in}>View Map In</a></td>
            <td><a href={"https://www.google.com/maps/search/?api=1&query=" + r.lat_out + "," + r.lng_out}>View Map Out</a></td>
            <td><a href={"edit/" + r.id}>Edit</a></td>
          </tr>
        ))}
      </tbody>
    </Table>
    </>
    :
    <form onSubmit={checkDay}>
      <Form.Group className="mb-3" controlId="formwork">
        <Form.Label>Day:</Form.Label>
        <Form.Control type="text" placeholder="Day" onChange={(v) => setDay(v.target.value)} />
        <Form.Label>Month:</Form.Label>
        <Form.Control type="text" placeholder="Month" onChange={(v) => setMonth(v.target.value)} />
        <Form.Label>Year:</Form.Label>
        <Form.Control type="text" placeholder="Year" onChange={(v) => setYear(v.target.value)} />
      </Form.Group>

    <Button variant="primary" type="submit">
      Submit
    </Button>
  </form>
    }
      </>
  )
}

function Monthly(){
  const [month, setMonth] = React.useState("");
  const [data, setData] = React.useState([]);
  const [show, setShow] = React.useState(false);

  const [totalHours, setTotalHours] = React.useState(0.0);
  const [totalSalary, setTotalSalary] = React.useState(0.0);

  const [arrayMonths, setArrayMonths] = React.useState([]);

  React.useEffect(() => {
    var nMonths;
    let today = new Date();
    let first = new Date(2021, 10, 1);
    var countYear = 2021;
    var array = [];
    nMonths = (today.getFullYear() - first.getFullYear()) * 12;
    nMonths -= first.getMonth();
    nMonths += today.getMonth();
    for(var i = 0; i<nMonths+1; i++){
      if((first.getMonth() + i)%12 === 0){
          countYear++;
      }
      array.push(((first.getMonth() + i)%12)+1 + "/" + countYear);
    }
    setArrayMonths(array);
  }, [])

  const checkWorker = (evt) => {
    evt.preventDefault();
    var h = 0.0;
    var s = 0.0;
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    };
    Promise.all([
        fetch(properties.api + '/public/report/month/' + month, requestOptions).then((response) => response.json().then((json) => {
          setData(json);
          json.forEach(a => {
            h += a.hours;
            s += parseFloat(a.salary);
          });
          setTotalSalary(s);
          setTotalHours(h);
          setShow(true);
        }).catch((error) => console.error(error))),
    ]).then(() => {
        
    })
  }

  return(
    <>
    { show ? 
    <>
    <h2>Monthly report</h2>
    <Table striped bordered hover>
        <thead>
          <tr>
            <th>NIF</th>
            <th>Name</th>
            <th>Hours</th>
            <th>Salary</th>
          </tr>
        </thead>
        <tbody>
          {data.map((r, i) => (
            <tr key={i}>
              <td>{r.worker.nif}</td>
              <td>{r.worker.name} {r.worker.surname}</td>
              <td>{r.hours}</td>
              <td>{r.salary} €</td>
            </tr>
          ))}
          <tr key={"totals"}>
            <td></td>
            <td><b>TOTAL</b></td>
            <td>{totalHours}</td>
            <td>{totalSalary} €</td>
          </tr>
        </tbody>
      </Table>
      </>
      : 
      <>
      <form onSubmit={checkWorker}>
        <Form.Group className="mb-3" controlId="formwork">
          <Form.Label>Month:</Form.Label>
          <Form.Select aria-label="Default select example" onChange={(v) => setMonth(v.target.value)}>
          <option value={0}>-- Select a month --</option>
          {arrayMonths.map((n) => 
            <option value={n}>{n}</option>
          )}
          </Form.Select>
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </form>
      </> }
    </>
  )
}

function ByConstruction(){
  const [month, setMonth] = React.useState("");
  const [construction, setConstruction] = React.useState("");
  const [data, setData] = React.useState([]);
  const [info, setInfo] = React.useState([]);
  const [show, setShow] = React.useState(false);

  const [constructions, setConstructions] = React.useState([]);
  const [arrayMonths, setArrayMonths] = React.useState([]);

  React.useEffect(() => {
    var nMonths;
    let today = new Date();
    let first = new Date(2021, 10, 1);
    var countYear = 2021;
    var array = [];
    nMonths = (today.getFullYear() - first.getFullYear()) * 12;
    nMonths -= first.getMonth();
    nMonths += today.getMonth();
    for(var i = 0; i<nMonths+1; i++){
      if((first.getMonth() + i)%12 === 0){
          countYear++;
      }
      array.push(((first.getMonth() + i)%12)+1 + "/" + countYear);
    }
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    };
    Promise.all([
        fetch(properties.api + '/public/constructions', requestOptions).then((response) => response.json().then((json) => {
          setConstructions(json);
          setArrayMonths(array);
        }).catch((error) => console.error(error))),
    ]).then(() => {
        
    })
  }, [])

  const checkWorker = (evt) => {
    evt.preventDefault();
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    };
    Promise.all([
        fetch(properties.api + '/public/report/construction/' + month + '/' + construction, requestOptions).then((response) => response.json().then((json) => {
          setData(json.report);
          setInfo(json.info);
          setShow(true);
        }).catch((error) => console.error(error))),
    ]).then(() => {
        
    })
  }

  return(
    <>
    { show ? 
    <>
    <h2>Monthly report for construction {info.construction}</h2>
    <p>
      Total monthly cost: {info.total} €
    </p>
    <Table striped bordered hover>
        <thead>
          <tr>
            <th>NIF</th>
            <th>Name</th>
            <th>Hours</th>
            <th>Salary</th>
          </tr>
        </thead>
        <tbody>
          {data.map((r, i) => (
            <tr key={i}>
              <td>{r.worker.nif}</td>
              <td>{r.worker.name} {r.worker.surname}</td>
              <td>{r.hours}</td>
              <td>{r.salary} €</td>
            </tr>
          ))}
        </tbody>
      </Table>
      </>
      : 
      <>
      <form onSubmit={checkWorker}>
        <Form.Group className="mb-3" controlId="formwork">
          <Form.Label>Month:</Form.Label>
          <Form.Select aria-label="Default select example" onChange={(v) => setMonth(v.target.value)}>
          <option value={0}>-- Select a month --</option>
          {arrayMonths.map((n) => 
            <option value={n}>{n}</option>
          )}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formwork2">
          <Form.Label>Construction:</Form.Label>
          <Form.Select aria-label="Default select example" onChange={(v) => setConstruction(v.target.value)}>
          <option value={0}>-- Select a construction --</option>
          {constructions.map((n) => 
            <option value={n.id}>{n.name}</option>
          )}
          </Form.Select>
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
        <p>
          <a href='/byconstructionfull'>Get full report for a construction</a>
        </p>
      </form>
      </> }
    </>
  )
}

function ByConstructionAlways(){
  const [construction, setConstruction] = React.useState("");
  const [data, setData] = React.useState([]);
  const [info, setInfo] = React.useState([]);
  const [show, setShow] = React.useState(false);

  const [constructions, setConstructions] = React.useState([]);

  React.useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    };
    Promise.all([
        fetch(properties.api + '/public/constructions', requestOptions).then((response) => response.json().then((json) => {
          setConstructions(json);
        }).catch((error) => console.error(error))),
    ]).then(() => {
        
    })
  }, [])

  const checkWorker = (evt) => {
    evt.preventDefault();
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    };
    Promise.all([
        fetch(properties.api + '/public/report/construction/always/' + construction, requestOptions).then((response) => response.json().then((json) => {
          setData(json.report);
          setInfo(json.info);
          setShow(true);
        }).catch((error) => console.error(error))),
    ]).then(() => {
        
    })
  }

  return(
    <>
    { show ? 
    <>
    <h2>Report for construction {info.construction}</h2>
    <p>
      Total cost: {info.total} €
    </p>
    <Table striped bordered hover>
        <thead>
          <tr>
            <th>NIF</th>
            <th>Name</th>
            <th>Hours</th>
            <th>Salary</th>
          </tr>
        </thead>
        <tbody>
          {data.map((r, i) => (
            <tr key={i}>
              <td>{r.worker.nif}</td>
              <td>{r.worker.name} {r.worker.surname}</td>
              <td>{r.hours}</td>
              <td>{r.salary} €</td>
            </tr>
          ))}
        </tbody>
      </Table>
      </>
      : 
      <>
      <form onSubmit={checkWorker}>
        <Form.Group className="mb-3" controlId="formwork2">
          <Form.Label>Construction:</Form.Label>
          <Form.Select aria-label="Default select example" onChange={(v) => setConstruction(v.target.value)}>
          <option value={0}>-- Select a construction --</option>
          {constructions.map((n) => 
            <option value={n.id}>{n.name}</option>
          )}
          </Form.Select>
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </form>
      </> }
    </>
  )
}

function Individual(){
  const [workers, setWorkers] = React.useState([]);
  const [worker, setWorker] = React.useState("");
  const [month, setMonth] = React.useState("");
  const [data, setData] = React.useState([]);
  const [show, setShow] = React.useState(false);

  const [arrayMonths, setArrayMonths] = React.useState([]);

  React.useEffect(() => {
    var nMonths;
    let today = new Date();
    let first = new Date(2021, 10, 1);
    var countYear = 2021;
    var array = [];
    nMonths = (today.getFullYear() - first.getFullYear()) * 12;
    nMonths -= first.getMonth();
    nMonths += today.getMonth();
    for(var i = 0; i<nMonths+1; i++){
      if((first.getMonth() + i)%12 === 0){
          countYear++;
      }
      array.push(((first.getMonth() + i)%12)+1 + "/" + countYear);
    }
    setArrayMonths(array);

    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    };
    Promise.all([
        fetch(properties.api + '/public/users', requestOptions).then((response) => response.json().then((json) => {
          setWorkers(json);
        }).catch((error) => console.error(error))),
    ]).then(() => {
        
    })
  }, [])

  const checkWorker = (evt) => {
    evt.preventDefault();
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    };
    Promise.all([
        fetch(properties.api + '/public/report/individual/' + month + '/' + worker, requestOptions).then((response) => response.json().then((json) => {
          setData(json);
          setShow(true);
        }).catch((error) => console.error(error))),
    ]).then(() => {
        
    })
  }

  return(
    <>
    { show ? 
    <>
    <h2>Monthly report for {data.worker.name} {data.worker.surname}</h2>
    <p>Hours this month: {data.hours}</p>
    <p>Salary this month: {data.salary} €</p>
    <Table striped bordered hover>
        <thead>
          <tr>
            <th>Date</th>
            <th>Clock-In Time</th>
            <th>Clock-Out Time</th>
            <th>Hours</th>
            <th>Construction In</th>
            <th>Construction Out</th>
            <th>Notes</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.clocks.map((r, i) => (
            <tr key={i}>
              <td>{r.date}</td>
              <td>{r.time_in}</td>
              <td>{r.timeOut}</td>
              <td>{r.hours}</td>
              <td>{r.construction_in.name}</td>
              <td>{r.construction_out == null ? "" : r.construction_out.name}</td>
              <td>{r.note}</td>
              <td><a href={"https://www.google.com/maps/search/?api=1&query=" + r.lat_in + "," + r.lng_in}>View Map In</a></td>
              <td><a href={"https://www.google.com/maps/search/?api=1&query=" + r.lat_out + "," + r.lng_out}>View Map Out</a></td>
            </tr>
          ))}
        </tbody>
      </Table>
      <h4>Hours per construction site:</h4>
      {Object.keys(data.constructions).map((r, i) => (
        <p>{r}: {data.constructions[r]}</p>
      ))}
      </>
      : 
      <>
      <form onSubmit={checkWorker}>
        <Form.Group className="mb-3" controlId="formwork1">
          <Form.Label>Worker:</Form.Label>
          <Form.Select aria-label="Default select example" onChange={(v) => setWorker(v.target.value)}>
          <option value={0}>-- Select a worker --</option>
          {workers.map((n) => 
            <option value={n.nif}>{n.name} {n.surname}</option>
          )}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formwork">
          <Form.Label>Month:</Form.Label>
          <Form.Select aria-label="Default select example" onChange={(v) => setMonth(v.target.value)}>
          <option value={0}>-- Select a month --</option>
          {arrayMonths.map((n) => 
            <option value={n}>{n}</option>
          )}
          </Form.Select>
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </form>
      </> }
    </>
  )
}

function AddClock(){
  const [nif, setNif] = React.useState("");
  const [timeIn, setTimeIn] = React.useState("");
  const [constructionIn, setConstructionIn] = React.useState("");
  const [timeOut, setTimeOut] = React.useState("");
  const [constructionOut, setConstructionOut] = React.useState("");
  const [date, setDate] = React.useState("");

  const [constructions, setConstructions] = React.useState([]);
  const [workers, setWorkers] = React.useState([]);

  const history = useHistory();

  React.useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    };
    Promise.all([
        fetch(properties.api + '/public/constructions', requestOptions).then((response) => response.json().then((json) => {
          setConstructions(json);
        }).catch((error) => console.error(error))),
        fetch(properties.api + '/public/users', requestOptions).then((response) => response.json().then((json) => {
          setWorkers(json);
        }).catch((error) => console.error(error))),
    ]).then(() => {
        
    })
  },[]);

  const create = (evt) => {
    evt.preventDefault();
    var postData;
    if(timeOut){
      postData = {'worker':{'nif': nif}, 'date': date, 'time_in': timeIn, 'timeOut': timeOut, 'construction_in': {'id': constructionIn}, 'construction_out': {'id': constructionOut}};
    }else{
      postData = {'worker':{'nif': nif}, 'date': date, 'time_in': timeIn, 'construction_in': {'id': constructionIn}};
    }
    console.log(postData);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(postData)
    };
    Promise.all([
        fetch(properties.api + '/public/addClock', requestOptions).then((response) => history.goBack()),
    ]).then(() => {
        
    })
  }

  return(
    <form onSubmit={create}>
      <Form.Group className="mb-3" controlId="formwork1">
          <Form.Label>Employee:</Form.Label>
          <Form.Select aria-label="Default select example" onChange={(v) => setNif(v.target.value)}>
          <option value={0}>-- Select a worker --</option>
          {workers.map((n) => 
            <option value={n.nif}>{n.name} {n.surname}</option>
          )}
          </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Date:</Form.Label>
        <Form.Control type="date" placeholder="" onChange={(v) => setDate(v.target.value)} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Time In:</Form.Label>
        <Form.Control type="text" placeholder="00:00" onChange={(v) => setTimeIn(v.target.value)} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formwork1">
          <Form.Label>Construction In:</Form.Label>
          <Form.Select aria-label="Default select example" onChange={(v) => setConstructionIn(v.target.value)}>
          <option value={0}>-- Select a construction --</option>
          {constructions.map((n) => 
            <option value={n.id}>{n.name}</option>
          )}
          </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Time Out:</Form.Label>
        <Form.Control type="text" placeholder="00:00" onChange={(v) => setTimeOut(v.target.value)} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formwork2">
          <Form.Label>Construction Out:</Form.Label>
          <Form.Select aria-label="Default select example" onChange={(v) => setConstructionOut(v.target.value)}>
          <option value={0}>-- Select a construction --</option>
          {constructions.map((n) => 
            <option value={n.id}>{n.name}</option>
          )}
          </Form.Select>
      </Form.Group>
      <Button variant="primary" type="submit">
        Submit
      </Button>
    </form>
  )
}

function EditClock(){
  const { id } = useParams();
  const [timeIn, setTimeIn] = React.useState("");
  const [constructionIn, setConstructionIn] = React.useState("");
  const [timeOut, setTimeOut] = React.useState("");
  const [constructionOut, setConstructionOut] = React.useState("");

  const [info, setInfo] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const [constructions, setConstructions] = React.useState([]);

  const history = useHistory();

  React.useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    };
    Promise.all([
        fetch(properties.api + '/public/constructions', requestOptions).then((response) => response.json().then((json) => {
          setConstructions(json);
        }).catch((error) => console.error(error))),
        fetch(properties.api + '/public/getClock/' + id, requestOptions).then((response) => response.json().then((json) => {
          console.log(json);
          setInfo(json);
          setTimeIn(json.time_in);
          setTimeOut(json.timeOut);
          setConstructionIn(json.construction_in.id);
          setConstructionOut(json.construction_out.id);
        }).catch((error) => console.error(error))),
    ]).then(() => {
        setLoading(false);
    })
  },[id]);

  const edit = (evt) => {
    evt.preventDefault();
    var postData;
    if(timeOut){
      postData = {'id': id, 'time_in': timeIn, 'timeOut': timeOut, 'construction_in': {'id': constructionIn}, 'construction_out': {'id': constructionOut}};
    }else{
      postData = {'id': id, 'time_in': timeIn, 'construction_in': {'id': constructionIn}};
    }
    console.log(postData);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(postData)
    };
    Promise.all([
        fetch(properties.api + '/public/editClock', requestOptions).then((response) => history.goBack()),
    ]).then(() => {
        
    })
  }

  const delet = (evt) => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    };
    Promise.all([
        fetch(properties.api + '/public/deleteClock/' + id, requestOptions).then((response) => history.goBack()),
    ]).then(() => {
        
    })
  }

  if(loading){
    return(<></>);
  }

  return(
    <form onSubmit={edit}>
      <Form.Group className="mb-3">
        <Form.Label>Employee:</Form.Label>
        <Form.Control type="text" placeholder="NIF" value={info.worker.name + ' ' + info.worker.surname} disabled={true} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Date:</Form.Label>
        <Form.Control type="date" placeholder="" value={info.date} disabled={true} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Time In:</Form.Label>
        <Form.Control type="text" placeholder="00:00" defaultValue={info.time_in} onChange={(v) => setTimeIn(v.target.value)} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formwork1">
          <Form.Label>Construction In:</Form.Label>
          <Form.Select aria-label="Default select example" onChange={(v) => setConstructionIn(v.target.value)} defaultValue={info.construction_in.id}>
          <option value={0}>-- Select a construction --</option>
          {constructions.map((n) => 
            <option value={n.id}>{n.name}</option>
          )}
          </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Time Out:</Form.Label>
        <Form.Control type="text" placeholder="00:00" defaultValue={info.timeOut ? info.timeOut : ""} onChange={(v) => setTimeOut(v.target.value)} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formwork2">
          <Form.Label>Construction Out:</Form.Label>
          <Form.Select aria-label="Default select example" onChange={(v) => setConstructionOut(v.target.value)} defaultValue={info.construction_out ? info.construction_out.id : 0}>
          <option value={0}>-- Select a construction --</option>
          {constructions.map((n) => 
            <option value={n.id}>{n.name}</option>
          )}
          </Form.Select>
      </Form.Group>
      <Button variant="primary" type="submit">
        Submit
      </Button>
      <Button variant="danger" onClick={() => delet()}>
        Delete
      </Button>
    </form>
    
  )
}

export default App;
